"use client"

import { Kbd } from "@nextui-org/kbd"
import { SwitchProps, useSwitch } from "@nextui-org/switch"
import { Tooltip } from "@nextui-org/tooltip"
import { useIsSSR } from "@react-aria/ssr"
import { VisuallyHidden } from "@react-aria/visually-hidden"
import clsx from "clsx"
import { useTheme } from "next-themes"
import { FC, useCallback, useEffect, useState } from "react"

export interface ThemeSwitchProps {
  className?: string
  classNames?: SwitchProps["classNames"]
  location?: string
  isShowText?: boolean
}

export const ThemeSwitch: FC<ThemeSwitchProps> = ({
  className,
  classNames,
  isShowText = false,
}) => {
  const { theme, setTheme } = useTheme()
  const isSSR = useIsSSR()

  const onChange = useCallback(() => {
    if (theme === "light") {
      setTheme("dark")
    } else {
      setTheme("light")
    }
  }, [theme, setTheme])

  const [switched, setSwitched] = useState<boolean>(false)

  const { Component, slots, getBaseProps, getInputProps, getWrapperProps } =
    useSwitch({
      "aria-label": `Switch to ${theme === "light" || isSSR ? "dark" : "light"} mode`,
      onChange,
    })

  useEffect(() => {
    setSwitched(theme === "light")
  }, [theme, isSSR, setSwitched])

  useEffect(() => {
    const handleKeyDown = (event: { metaKey: any; key: string }) => {
      if (Boolean(event.metaKey) && event.key === "k") {
        onChange()
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [onChange])

  return (
    <Component
      {...getBaseProps({
        className: clsx(
          "transition-opacity hover:opacity-80 cursor-pointer",
          className,
          classNames?.base,
        ),
      })}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <div
        {...getWrapperProps()}
        className={slots.wrapper({
          class: clsx(
            [
              "h-auto w-auto",
              "bg-transparent",
              "rounded-lg",
              "flex items-center justify-center gap-2",
              "group-data-[selected=true]:bg-transparent",

              "pt-px",
              "px-0",
              "mx-0",
            ],
            classNames?.wrapper,
          ),
        })}
      >
        <Tooltip
          content={
            <Kbd className="bg-transparent shadow-none" keys={["command"]}>
              +K
            </Kbd>
          }
          delay={600}
        >
          <button
            type="button"
            onClick={onChange}
            className="flex items-center gap-2"
          >
            {!switched || isSSR ? (
              <>
                <i className="ri-sun-line text-lg" />
                {isShowText && <span>ライトモード</span>}
              </>
            ) : (
              <>
                <i className="ri-moon-line text-lg" />
                {isShowText && <span>ダークモード</span>}
              </>
            )}
          </button>
        </Tooltip>
      </div>
    </Component>
  )
}
