import { Button, ButtonProps } from "@nextui-org/button"
import React from "react"

type OzButtonColor = "primary" | "secondary" | "success" | "warning" | "danger"
interface OzButtonProps extends Omit<ButtonProps, "color"> {
  color?: OzButtonColor
  variant?:
    | "solid"
    | "bordered"
    | "light"
    | "flat"
    | "faded"
    | "shadow"
    | "ghost"
  size?: "sm" | "md" | "lg"
  radius?: "sm" | "md" | "lg" | "none" | "full" | undefined
}
export const OrganizeButton: React.FC<OzButtonProps> = ({
  children,
  color = "primary",
  variant = "solid",
  size = "md",
  radius = "sm",
  ...props
}) => {
  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      radius={radius}
      className={`font-medium ${variant === "solid" ? "text-white" : ""}`}
      {...props}
    >
      {children}
    </Button>
  )
}
