import { Link } from "@nextui-org/link"
import clsx from "clsx"
import NextLink from "next/link"

import { DiscordIcon, TwitterIcon } from "./icons"
import { ThemeSwitch } from "./theme-switch"

import { fontMontserrat } from "@/config/fonts"
import { linkConfig } from "@/config/link"
import { siteConfig } from "@/config/site"

interface SubFooterProps {
  mode?: "dark" | "default"
}

export const SubFooter = ({ mode = "default" }: SubFooterProps) => {
  // モードに応じてテキストカラーを決定
  const textColorClass =
    mode === "dark"
      ? "text-gray-300 hover:text-primary"
      : "text-default-500 hover:text-white"

  return (
    <div className="flex flex-col items-center justify-center space-y-4">
      <div
        className={clsx(
          "flex flex-wrap items-center justify-center gap-5 font-mono text-sm",
          fontMontserrat.variable,
        )}
      >
        <NextLink
          href={linkConfig.privacy}
          className={clsx("transition-colors", textColorClass)}
        >
          プライバシーポリシー
        </NextLink>
        <NextLink
          href={linkConfig.terms}
          className={clsx("transition-colors", textColorClass)}
        >
          利用規約
        </NextLink>

        <Link isExternal href={siteConfig.links.x} aria-label="Twitter">
          <TwitterIcon
            className={clsx("transition-colors", textColorClass)}
            size={24}
          />
        </Link>
        <Link isExternal href={siteConfig.links.discord} aria-label="Discord">
          <DiscordIcon
            className={clsx("transition-colors", textColorClass)}
            size={28}
          />
        </Link>
        <ThemeSwitch />
      </div>
      <p
        className={clsx(
          "text-xs",
          mode === "dark" ? "text-gray-400" : "text-default-500",
        )}
      >
        © {new Date().getFullYear()} CoPalette. Inc,
      </p>
    </div>
  )
}
