import { Accordion, AccordionItem } from "@nextui-org/accordion"
import { NavbarMenu, NavbarMenuToggle } from "@nextui-org/navbar"
import clsx from "clsx"
import NextLink from "next/link"
import React, { useCallback } from "react"

import { SubFooter } from "./SubFooter"

import { OrganizeButton } from "@/app/organize/_components/OrganizeButton"
import { fontMontserrat } from "@/config/fonts"
import { PATH } from "@/constants/path"

type MenuItem = {
  title: string
  items: {
    label: string
    href: string
  }[]
}

type HamburgerMenuProps = {
  menuItems: MenuItem[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  theme?: "light" | "dark"
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  menuItems,
  isOpen,
  setIsOpen,
  theme,
}) => {
  const handleLinkClick = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const themeClasses = {
    light: {
      menu: "bg-white",
      text: "text-black",
    },
    dark: {
      menu: "bg-black",
      text: "text-white",
    },
  }

  const defaultClasses = {
    menu: "bg-background",
    text: "text-foreground",
    hover: "text-primary",
    indicator: "currentColor",
    trigger: "data-[hover=true]:bg-default-100",
  }

  const currentTheme = theme ? themeClasses[theme] : defaultClasses

  const itemClasses = {
    base: "py-0 w-full",
    title: `font-normal text-medium ${currentTheme.text}`,
    trigger: "px-2 py-0 rounded-lg h-14 flex items-center",
    indicator: currentTheme.text,
    content: "text-small px-2",
  }

  return (
    <div className="h-5 w-5">
      <NavbarMenuToggle
        className={currentTheme.text}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      />
      <NavbarMenu
        className={clsx(
          "flex flex-col justify-between pb-10 lg:items-center",
          currentTheme.menu,
        )}
      >
        <Accordion
          className="flex max-w-3xl flex-col items-center justify-center"
          itemClasses={itemClasses}
        >
          {menuItems.map((menuItem, index) => (
            <AccordionItem
              key={menuItem.title}
              aria-label={menuItem.title}
              title={
                <span
                  className={clsx(
                    "text-md font-mono font-bold",
                    currentTheme.text,
                    fontMontserrat.variable,
                  )}
                >
                  {menuItem.title}
                </span>
              }
              indicator={<i className="ri-arrow-down-s-line" />}
              className={clsx(
                "py-2",
                index !== menuItems.length - 1 && "border-b border-gray-200",
              )}
            >
              <div className="flex flex-col gap-4 pl-2">
                {menuItem.items.map((item) => (
                  <NextLink
                    key={item.label}
                    href={item.href}
                    className={clsx(
                      "font-mono text-sm",
                      currentTheme.text,
                      fontMontserrat.variable,
                    )}
                    onClick={handleLinkClick}
                  >
                    {item.label}
                  </NextLink>
                ))}
              </div>
            </AccordionItem>
          ))}
        </Accordion>
        <div className="w-full space-y-4">
          <NextLink
            href={PATH.ORGANIZE.CONTACT}
            className="min-w-[240px] max-w-7xl"
          >
            <OrganizeButton className="w-full max-w-7xl">
              お問い合わせ
            </OrganizeButton>
          </NextLink>
          <SubFooter />
        </div>
      </NavbarMenu>
    </div>
  )
}

export default HamburgerMenu
